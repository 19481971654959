<template>
    <v-dialog
      v-model="showDialogIntern"
      width="500"
    >
      <v-card>
        <v-card-title class="grey lighten-2">
          Farbe auswählen
        </v-card-title>
        <v-card-text class="mt-4">
          <v-color-picker
            dot-size="25"
            mode="hexa"
            swatches-max-height="200"
            v-model="colorIntern"
          ></v-color-picker>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="showDialogIntern = false, $emit('closeDialog')"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'KpiDisplay',
  components: {},
  props: {
    color: {
      type: String,
      required: false,
      default: undefined
    },
    showDialog: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: undefined
    },
  },
  data: () => ({
    colorIntern: undefined,
    showDialogIntern: false
  }),
  computed: {

  },
  watch: {
    showDialog(val) {
      this.showDialogIntern = val;
    },
    color(val) {
      this.colorIntern = val;
    },
    colorIntern(val) {
      this.$emit('updateColor', val, this.type);
    }
  },
  mounted() {
    this.colorIntern = this.color;
    this.showDialogIntern = this.showDialog;
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">

</style>
